import Pagination from '@mui/material/Pagination';
import { graphql, navigate } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/Layout/Layout';
import Post from '../../components/Post/Post';
import Sidebar from '../../components/Sidebar/Sidebar';
import icon32 from '../favicon.jpg';

const IndexRoute = ({ data, location }) => {
  const posts = data.allMarkdownRemark.edges;
  const siteTitle = data.site.siteMetadata?.title || 'Title';

  const items = posts.map((post) => (
    <Post
      data={post}
      key={post.node.fields.slug}
      lang={post.node.frontmatter.lang}
    />
  ));

  const gotoPage = (evt, page) => {
    if (page === 1) {
      navigate('/es');
    } else {
      navigate(`/es/page/${page}`);
    }
  };

  return (
    <Layout location={location} title={siteTitle}>
      <Helmet>
        <title>Pensamientos de Thomas Derflinger</title>
        <meta
          name="description"
          content="Soy un empresario visionario y desarrollador de software. En este blog escribo principalmente sobre programación web y temas relacionados como el IoT."
        />
        <link rel="shortcut icon" type="image/jpeg" href={icon32} />
      </Helmet>
      <Sidebar data={data} lang="es" />
      <div className="content">
        <div className="mt-10 mx-6 mb-4">{items}</div>
        <Pagination
          count={1}
          color="primary"
          className="mb-8"
          onChange={gotoPage}
        />
      </div>
    </Layout>
  );
}

export default IndexRoute;

export const pageQuery = graphql`
  query IndexEsQuery {
    site {
      siteMetadata {
        title
        url
        author {
          name
          nameFr
          twitter
          github
          rss
        }
      }
    }
    allMarkdownRemark(
      limit: 8
      filter: {
        frontmatter: {
          layout: { eq: "post" }
          draft: { ne: true }
          lang: { eq: "es" }
        }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
            categorySlug
          }
          frontmatter {
            title
            lang
            path
            pathFr
            license
            date
            category
            description
            attachments {
              childImageSharp {
                gatsbyImageData(
                  width: 150
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`;
